import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 发票记录 - 分页查询
 * @param params
 */
export function getpaidOrderList(params) {
  return request({
    url: envInfo.bgApp.customerWXJWPath + '/paidOrder/queryList',
    method: 'get',
    params,
  });
}

/**
 * 发票记录 - 导出
 * @param params
 */
export function paidListExport(params) {
  return request({
    url: envInfo.bgApp.customerWXJWPath + '/paidOrder/export',
    method: 'get',
    params,
  });
}

/**
 * 修改开票状态
 * @param params
 */
export function editInvoiceStatus(params) {
  return request({
    url: envInfo.bgApp.customerWXJWPath + '/paidOrder/editInvoiceStatus',
    method: 'post',
    data: params,
  });
}
/**
 * 修改月结
 * @param params
 */
export function editCardStatus(params) {
  return request({
    url: envInfo.bgApp.customerWXJWPath + '/paidOrder/monthlySettlementFlag',
    method: 'post',
    data: params,
  });
}
/**
 * 通过orderId查详情
 * @param params
 */
export function queryOrderById(params) {
  return request({
    url: envInfo.bgApp.customerWXJWPath + '/order/queryOrderById',
    method: 'get',
    params,
  });
}
